import React, { useContext } from 'react'
import { Link } from 'gatsby'
import { MdPayment } from 'react-icons/md'
import './cart.styles.css'

import StoreContext from '~/context/StoreContext'
import LineItem from './LineItem'

const minPriceOrder = process.env.GATSBY_SHOP_MIN_ORDER || 30
const freeShippingAmount = process.env.GATSBY_SHOP_FREE_SHIPPING_AMOUNT || 60

const Cart = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext)
  const minOrderReached = checkout.totalPrice >= minPriceOrder
  const freeShippingReached = checkout.totalPrice >= freeShippingAmount

  const handleCheckout = () => {
    if (window.fbq) {
      window.fbq('track', 'InitiateCheckout')
    }
    window.open(checkout.webUrl)
  }

  return (
    <div className="cart-container">
      {checkout.lineItems.length ? (
        checkout.lineItems.map((line_item) => {
          return <LineItem key={line_item.id.toString()} line_item={line_item} />
        })
      ) : (
        <div className="empty-cart">
          <p>Todavía no hay ninguna cerveza en la cesta</p>
          <Link to="/" className="btn btn_hover">
            Seguir comprando
          </Link>
        </div>
      )}
      <br />
      <div className={minOrderReached ? '' : 'checkout_disabled'}>
        <button
          onClick={handleCheckout}
          className="btn btn_wide btn_hover btn_large"
          disabled={!minOrderReached}
        >
          {minOrderReached
            ? `Pagar ${checkout.totalPrice} €`
            : `El pedido mínimo es de ${minPriceOrder}€`}
          <MdPayment />
        </button>
        <span className="checkout_legend checkout_legend_invalid">
          {freeShippingReached
            ? '¡Gastos de envío gratis!'
            : `Gastos de envío calculados en el pago ¡ Gratis a partir de ${freeShippingAmount}€ !`}
        </span>
      </div>
    </div>
  )
}

export default Cart
