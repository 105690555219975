import React, { useContext } from 'react'

import StoreContext from '~/context/StoreContext'

const latasInSku = process.env.GATSBY_SHOP_UNITS_IN_SKY || 2
const LineItem = ({ line_item }) => {
  const {
    id,
    title,
    quantity,
    variant: { price },
  } = line_item
  const {
    updateLineItem,
    removeLineItem,
    store: { client, checkout },
  } = useContext(StoreContext)

  const handleUpdate = (amount) => {
    updateLineItem(client, checkout.id, id, quantity + amount)
  }

  const handleRemove = () => {
    removeLineItem(client, checkout.id, id)
  }

  return (
    <div className="cart_item_container">
      <div className="cart_item">
        {line_item.variant.image ? (
          <img
            src={line_item.variant.image.src}
            alt={`${line_item.title} product shot`}
            height="200px"
          />
        ) : null}
        <div className="cart_item_text_container">
          <span className="cart_item_text" style={{ fontWeight: 'bold' }}>
            {title}
          </span>
          <span className="cart_item_text">
            <strong>
              {quantity} pack{quantity > 1 ? 's' : ''}
            </strong>{' '}
            ({quantity * latasInSku} latas)
          </span>
          <span className="cart_item_text">
            <strong>{price * quantity}€</strong> ({price / latasInSku}€ / lata)
          </span>
        </div>
      </div>
      <div className="cart_btn_container">
        {quantity > 1 && (
          <span className="cart_item_btn">
            <button className="cart_btn" onClick={() => handleUpdate(-1)}>
              <span>-</span>
            </button>
          </span>
        )}
        <span className="cart_item_btn">
          <button className="cart_btn" onClick={() => handleUpdate(1)}>
            <span>+</span>
          </button>
        </span>
        <button className="cart_btn" onClick={handleRemove}>
          <span>&times;</span>
        </button>
      </div>
    </div>
  )
}

export default LineItem
