import React from 'react'

import Cart from '~/components/Cart'
import Navigation from '~/components/Navigation'
import './cart.styles.css'

const CartPage = () => (
  <div className="cart-page">
    <h1>Resumen de compra</h1>
    <Navigation back />
    <Cart />
  </div>
)

export default CartPage
